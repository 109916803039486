.feature-highlight {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

  .feature-highlight__overlay {
    cursor: not-allowed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .feature-highlight__content {
    position: relative;
    width: 60%;
    display: flex;
    margin-bottom: var(--spacing-4);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--spacing-3);
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-800);
    border-right-width: 3px;
    border-bottom-width: 3px;
    border-radius: 8px;
  }

  .feature-highlight__close {
    margin-top: var(--spacing-4);
  }

    .feature-highlight__close a {
      text-decoration: none;
      font-size: var(--font-size-xxsmall);
      color: var(--color-black);
    }

  .feature-highlight__emoji {
    font-size: 1.5rem;
  }

  .feature-highlight__title {
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-4);
    color: var(--color-black);
    font-weight: 600;
    letter-spacing: -.03rem;
    text-align: center;
  }
  
  .feature-highlight__text {
    color: var(--color-gray-700);
    font-size: var(--font-size-xsmall);
    line-height: 1.3;
  }